* {
  box-sizing: border-box;
}
.loader {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 1240px;
  position: fixed;
  background-color: #1d2228;
  z-index: 100;
  transition: background 1s ease-in-out;
}
.container {
  position: relative;
  display: block;
  width: 40vh;
  height: 40vh;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(121deg) rotateX(45deg);
}
.circle:nth-child(0) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 0%;
  top: 0%;
  width: 70%;
  height: 70%;
  -webkit-animation: spin Infinitys infinite linear;
  animation: spin Infinitys infinite linear;
}
.circle:nth-child(1) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 2.1875%;
  top: 2.1875%;
  width: 65.625%;
  height: 65.625%;
  -webkit-animation: spin 12s infinite linear;
  animation: spin 12s infinite linear;
}
.circle:nth-child(2) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 4.375%;
  top: 4.375%;
  width: 61.25%;
  height: 61.25%;
  -webkit-animation: spin 6s infinite linear;
  animation: spin 6s infinite linear;
}
.circle:nth-child(3) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 6.5625%;
  top: 6.5625%;
  width: 56.875%;
  height: 56.875%;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
}
.circle:nth-child(4) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 8.75%;
  top: 8.75%;
  width: 52.5%;
  height: 52.5%;
  -webkit-animation: spin 3s infinite linear;
  animation: spin 3s infinite linear;
}
.circle:nth-child(5) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 10.9375%;
  top: 10.9375%;
  width: 48.125%;
  height: 48.125%;
  -webkit-animation: spin 2.4s infinite linear;
  animation: spin 2.4s infinite linear;
}
.circle:nth-child(6) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 13.125%;
  top: 13.125%;
  width: 43.75%;
  height: 43.75%;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.circle:nth-child(7) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 15.3125%;
  top: 15.3125%;
  width: 39.375%;
  height: 39.375%;
  -webkit-animation: spin 1.714285714285714s infinite linear;
  animation: spin 1.714285714285714s infinite linear;
}
.circle:nth-child(8) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 17.5%;
  top: 17.5%;
  width: 35%;
  height: 35%;
  -webkit-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
}
.circle:nth-child(9) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 19.6875%;
  top: 19.6875%;
  width: 30.625%;
  height: 30.625%;
  -webkit-animation: spin 1.333333333333333s infinite linear;
  animation: spin 1.333333333333333s infinite linear;
}
.circle:nth-child(10) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 21.875%;
  top: 21.875%;
  width: 26.25%;
  height: 26.25%;
  -webkit-animation: spin 1.2s infinite linear;
  animation: spin 1.2s infinite linear;
}
.circle:nth-child(11) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 24.0625%;
  top: 24.0625%;
  width: 21.875%;
  height: 21.875%;
  -webkit-animation: spin 1.090909090909091s infinite linear;
  animation: spin 1.090909090909091s infinite linear;
}
.circle:nth-child(12) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 26.25%;
  top: 26.25%;
  width: 17.5%;
  height: 17.5%;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
.circle:nth-child(13) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 28.4375%;
  top: 28.4375%;
  width: 13.125%;
  height: 13.125%;
  -webkit-animation: spin 0.923076923076923s infinite linear;
  animation: spin 0.923076923076923s infinite linear;
}
.circle:nth-child(14) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 30.625%;
  top: 30.625%;
  width: 8.75%;
  height: 8.75%;
  -webkit-animation: spin 0.857142857142857s infinite linear;
  animation: spin 0.857142857142857s infinite linear;
}
.circle:nth-child(15) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 32.8125%;
  top: 32.8125%;
  width: 4.375%;
  height: 4.375%;
  -webkit-animation: spin 0.8s infinite linear;
  animation: spin 0.8s infinite linear;
}
.circle:nth-child(16) {
  position: absolute;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  left: 35%;
  top: 35%;
  width: 0%;
  height: 0%;
  -webkit-animation: spin 0.75s infinite linear;
  animation: spin 0.75s infinite linear;
}
.circle:nth-child(2n) {
  border: 2px dashed rgba(255, 255, 255, 0.5);
}
.circle:last-child {
  display: none;
}
@-webkit-keyframes spin {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.light {
  background-color: #f4f4f4;
  .circle:nth-child(0),
  .circle:nth-child(1),
  .circle:nth-child(2),
  .circle:nth-child(3),
  .circle:nth-child(4),
  .circle:nth-child(5),
  .circle:nth-child(6),
  .circle:nth-child(7),
  .circle:nth-child(8),
  .circle:nth-child(9),
  .circle:nth-child(10),
  .circle:nth-child(11),
  .circle:nth-child(12),
  .circle:nth-child(13),
  .circle:nth-child(14),
  .circle:nth-child(15),
  .circle:nth-child(16) {
    border: 2px solid #1d2228;
  }
  .circle:nth-child(2n) {
    border: 2px dashed rgba(29, 34, 40, 0.5);
  }
}
